import { ref } from 'valtio';

type SnackbarType = 'error' | 'success' | 'info';

class SnackbarState {
  message = '';
  type: SnackbarType = 'error';
  icon = '';
  id = '';

  onCloseCallback = () => {};
  onOpenCallback = () => {};

  setMessage({
    message,
    type = 'error',
    icon = '',
    id = '',
  }: {
    message: string;
    type?: SnackbarType;
    icon?: string;
    id?: string;
  }) {
    this.message = message;
    this.type = type;
    this.icon = icon;
    this.id = id;

    this.onOpenCallback();
    // Close after 4 seconds
    setTimeout(() => {
      this.close();
    }, 3000);
  }

  registerOnCloseCallback(cb: VoidFunction) {
    this.onCloseCallback = cb;
  }

  registerOnOpenCallback(cb: VoidFunction) {
    this.onOpenCallback = cb;
  }

  close() {
    this.message = '';
    this.onCloseCallback();
  }
}

export const snackbarState = ref(new SnackbarState());
