import { useEffect } from 'react';

export const useDisableBodyScroll = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      const bodyWidth = document.body.clientWidth;
      document.body.style.overflow = 'hidden';
      document.body.style.width = `${bodyWidth}px`;
    }
    return () => {
      document.body.style.overflow = '';
      document.body.style.width = 'auto';
    };
  }, [isOpen]);
};
