import React, { useCallback, useEffect } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  children: React.ReactNode;
  shouldCloseOnOutsideClick?: boolean;
  shouldCloseOnEscKey?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  shouldCloseOnOutsideClick = true,
  shouldCloseOnEscKey = true,
}) => {
  const modalListener = useCallback(
    (e: MouseEvent) => {
      if ((e.target as HTMLElement).id === 'modal' && shouldCloseOnOutsideClick) {
        onClose();
      }
    },
    [onClose, shouldCloseOnOutsideClick],
  );

  const keyboardListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && shouldCloseOnEscKey) {
        onClose();
      }
    },
    [onClose, shouldCloseOnEscKey],
  );

  useEffect(() => {
    const modal = document.getElementById('modal');

    if (isOpen) {
      modal?.addEventListener('click', modalListener);
      window.addEventListener('keydown', keyboardListener);
    }

    return () => {
      modal?.removeEventListener('click', modalListener);
      window.removeEventListener('keydown', keyboardListener);
    };
  }, [isOpen, modalListener, keyboardListener]);

  if (isOpen) {
    return (
      <div
        id="modal"
        className="w-full h-full left-1/2 top-1/2 fixed -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center z-10 bg-black/75 animate-[fadeIn_0.3s]"
      >
        {children}
      </div>
    );
  }
  return null;
};
