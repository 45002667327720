import React, { useState } from 'react';
import styles from './tooltip.module.css';
import clsx from 'clsx';

interface TooltipProps {
  text: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  spacing?: 'normal' | 'compact' | 'tall';
}

export const Tooltip: React.FC<TooltipProps> = ({
  text,
  children,
  className,
  spacing = 'normal',
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  if (!text) return <>{children}</>;
  return (
    <div
      className={className ?? styles.container}
      onFocus={() => {}}
      onMouseOver={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip ? (
        <span
          className={`rounded-md ${clsx(styles.tooltip, { [styles.tooltipTall]: spacing === 'tall', [styles.tooltipCompact]: spacing === 'compact' })} after:absolute after:top-full after:h-0 after:w-0 after:border-x-4 after:border-x-transparent after:border-t-[4px] after:border-t-black`}
        >
          {text}
        </span>
      ) : null}
    </div>
  );
};
